:root {
    --bluesnavy-10: rgba(13, 24, 70, 0.1);
    --bupa-navynavy-60: rgba(13, 24, 70, 0.6);
    --light-slides-color-1: rgba(0, 0, 0, 1);
    --light-slides-color-6: rgba(0, 121, 200, 1);
    --white: rgba(255, 255, 255, 1);
  }

  .frame-slect-lable{
    color: rgba(13, 24, 70, 0.60);
    font-family: Montserrat;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
.frame-select {
  border: 1px solid #dcdcdc !important;
  border-radius: 12px !important;
  padding: 10px 14px;
  width: 100% !important;
  }

  .search-box-frame{
    display: flex;
    background-color: #fff;
    border: 1px solid #dcdcdc !important;
    border-radius: 12px !important;
    padding: 6px;
    width: 100% !important;
    align-items: center;
    color: #b8b6b6;
  }

  .frame-control {
    background-color: var(--white);
    border: 0px solid;
    border-color: var(--bluesnavy-10) !important;
    border-radius: 4px;
    color: rgba(13, 24, 70, 0.60);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .frame-control::placeholder{
    color: var(--blues-navy-40, rgba(13, 24, 70, 0.40));
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .add-serch-button{
    display: flex;
    padding: 0.5rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    border-radius: 10px;
    background: #0079C8;
    color: var(--White-White, #FFF);
    border: 0;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }

  .icon-filters{
    display: flex;
    padding: 0.7rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 2rem;
    color: #3849B9;
    font-weight: 500;
    line-height: 1;
  }

  .icon-filters:hover{
    background: rgba(100, 77, 237, 0.08);
    color: #3849B9;
  }

.ContentMainPage {
    /* background-color: var(--body-bg); */
}

.ContentMainPage{
  padding: 10px 20px;
}

.member-MainPage{
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 14px;
  /* height: 87vh; */
  display: block;
  width: 100%;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  /* margin-bottom: 4rem !important; */
}

.member-MainPage::-webkit-scrollbar{
  display: none;
}

.ContentMainPage .main-title {
  font-size: 22px;
  color: #656565;
  font-weight: 600;
  margin-bottom: 10px;
}

.btn-100{
  width: 100%;
}
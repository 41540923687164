.member-profile-box{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 3px 12px rgb(0 0 0 / 9%);
    color: #000;
    /* display: block; */
    padding: 8px;
    width: 100%;
    border: 2px solid #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.brd-color-red{
    border: 2px solid #E61E39;  
}
.member-profile-box-header-title{
    display: flex;
    justify-content: space-between;
}

.member-profile-images{
    display: flex;
    align-items: center;
    gap: 0.625rem;
}

.member-profile-images-name{
    display: flex;
    align-items: center;
    gap: 0.625rem;
}

.member-profile-images-name h3{
    color: #0D1846;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.02rem;
    margin: 0;
}

.member-profile-box-header-actions{
    display: flex;
    align-items: center;
    gap: 0.625rem;
}

.member-profile-box-header-actions-button{
    background-color: #fff;
    border: 1px solid #e8edf7;
    border-radius: 42px;
    display: block;
    width: 32px;
    height: 32px;
    line-height: .8;
}

.member-profile-images-img {
    background-color: #fff;
    border: 1px solid #e8edf7;
    border-radius: 42px;
    display: block;
    width: 32px;
    height: 32px;
    object-fit: cover;
}

.member-profile-box-body{
    /* display: flex; */
        width: 100%;
        /* justify-content: space-between; */
}

.member-profile-box-body-item{
    color: var(--navy-navy-100, #0D1846);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; 
}

.member-profile-box-body-item .profile-card-title {
    color: #333;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0.2rem;
    text-align: left;
    display: flex;
    align-items: center;
}

.member-profile-box-body-item .profile-card-title span {
    color: #636773;
    font-size: 11px;
    font-weight: 400;
    padding-top: .2rem;
}

.member-profile-box-body-item .profile-card-subtitle {
    color: #636773;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
    text-align: left;
    display: flex;
    align-items: center;
    }

.member-profile-box-header-actions-button-container{
    display: flex;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 42px;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    min-width: 28px;
    height: 28px;
}

.member-profile-box-footer-item h4{
    color: #787486;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}
.member-profile-box-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.member-profile-box-footer-item {
    /* width: 50%; */
}
.member-profile-box-footer-item h5{
    color: #006DED;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.member-profile-box-footer-item h6{
    color: #101010;
    font-size: 11px;
    font-style: normal;
    font-weight: 00;
    line-height: normal;
    margin-bottom: 0;
}
.member-profile-box-footer-item1{
    width: 100%;
}
.member-profile-box-footer-item1 h3{
    display: flex;
        height: 32px;
        padding: 6px;
        gap: 12px;
        align-self: stretch;
        border-radius: 42px;
        background: var(--orange-orange-10, rgba(236, 137, 56, 0.10));
        width: 100%;
        color: var(--navy-navy-100, #0D1846);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
        align-items: center;
        justify-content: space-between;
}
/* .profile-bg-orange {
    background-color: #EC8938;
} */

/* .profile-bg-green{
    background-color: #1B883C;
} */

/* .profile-bg-red{
    background-color: #E61E39;
} */

.profile-bg-red {
    background-color: #ffe7ea;
    display: flex;
    gap: 6px;
    justify-content: center;
    color: #333;
}
.profile-bg-red i {
    color: #e61e39;
}

/* Orange/Warning style */
.profile-bg-orange {
    background-color: #fff5e6;
    display: flex;
    gap: 6px;
    justify-content: center;
    color: #333;
}
.profile-bg-orange i {
    color: #ff9800;
}

/* Green/Success style */
.profile-bg-green {
    background-color: #e6f7eb;
    display: flex;
    gap: 6px;
    justify-content: center;
    color: #333;
}
.profile-bg-green i {
    color: #28a745;
}

.profile-dot-Spo2 { 
    width: 6px;
    height: 6px;
    background-color: #006DED;
    display: inline-block;
    border-radius: 10px;
    margin-right: 4px;
}

.profile-dot-Bpm { 
    width: 6px;
    height: 6px;
    background-color: #A3CBF8;
    display: inline-block;
    border-radius: 10px;
    margin-right: 4px;
}

.profile-dot-RRp { 
    width: 6px;
    height: 6px;
    background-color: #CCE2FB;
    display: inline-block;
    border-radius: 10px;
    margin-right: 4px;
}

